export default [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "SlackIcon",
    meta: {
      staff: true,
      director: true,
    },
  },
  {
    title: "Inbox",
    route: "apps-email",
    icon: "MessageSquareIcon",
    meta: {
      staff: true,
      director: true,
    },
  },
  {
    title: "Calendar",
    route: "calendar",
    icon: "CalendarIcon",
    meta: {
      staff: true,
      director: true,
    },
  },
  { header: "MODULES", meta: { director: true } },
  {
    title: "HRM",
    icon: "UsersIcon",
    tag: 5,
    tagVariant: "light-primary",
    meta: {
      director: true,
    },
    children: [
      // {
      //   title: "Dashboard",
      //   route: "hrm",
      //   meta: {
      //     director: true,
      //   },
      // },
      {
        title: "Employee",
        route: "hrm/employee",
        meta: {
          director: true,
        },
      },
      {
        title: "Organization Chart",
        route: "hrm/organization",
        meta: {
          director: true,
        },
      },
      // {
      //   title: 'Contract',
      //   route: 'hrm/contract',
      // },
      {
        title: "Department",
        route: "hrm/department",
        meta: {
          director: true,
        },
      },
      {
        title: "Job Position",
        route: "hrm/job-position",
        meta: {
          director: true,
        },
      },
      {
        title: "Job Level",
        route: "hrm/job-level",
        meta: {
          director: true,
        },
      },
    ],
  },
  {
    title: "Time Management",
    icon: "ClockIcon",
    tag: 3,
    tagVariant: "light-primary",
    meta: {
      staff: true,
      director: true,
    },
    children: [
      // {
      //   title: "Dashboard",
      //   route: "timesheet",
      //   meta: {
      //     staff: true,
      //     director: true,
      //   },
      // },
      // {
      //   title: 'Live Attendance',
      //   route: 'timesheet/live-attendance',
      //   meta: {
      //     staff: true,
      //   }
      // },
      {
        title: "Attendances",
        route: "timesheet/attendance",
        meta: {
          staff: true,
          director: true,
        },
      },
      {
        title: "Leave",
        route: "timesheet/leave",
        meta: {
          staff: true,
          director: true,
        },
      },
      {
        title: "Overtime",
        route: "timesheet/overtime",
        meta: {
          staff: true,
          director: true,
        },
      },
      {
        title: "Setting",
        route: "timesheet/setting",
        meta: {
          staff: true,
          director: true,
        },
      },
    ],
  },
  {
    title: "Performance Management",
    icon: "AwardIcon",
    tag: 5,
    tagVariant: "light-primary",
    meta: {
      staff: true,
      director: true,
    },
    children: [
      // {
      //   title: "Dashboard",
      //   route: "performance",
      //   meta: {
      //     director: true,
      //   },
      // },
      {
        title: "Goals",
        tag: "3",
        tagVariant: "light-warning",
        meta: {
          staff: true,
          director: true,
        },
        children: [
          {
            title: "Goals Setting",
            route: "performance/goals-setting",
          },
          {
            title: "Mid Year",
            route: "performance/goals-mid-year",
          },
          {
            title: "Finnal Annual",
            route: "performance/goals-final-annual",
          },
        ],
      },
      {
        title: "PMP",
        route: "performance/pmp",
        meta: {
          staff: true,
          director: false,
        },
      },
      {
        title: "Talent Card",
        route: "performance/talent-card",
        meta: {
          staff: true,
          director: false,
        },
      },
      {
        title: "Key Succession",
        route: "performance/key-succession",
        meta: {
          staff: false,
          director: true,
        },
      },
      // {
      //   title: 'Reports',
      //   route: 'performance/reports',
      //   meta: {
      //     staff: false
      //   }
      // }
    ],
  },
  {
    title: "Project Management",
    icon: "BriefcaseIcon",
    tag: 2,
    tagVariant: "light-primary",
    meta: {
      staff: true,
      director: true,
    },
    children: [
      // {
      //   title: 'Dashboard',
      //   route: 'project',
      // },
      {
        title: "Projects",
        route: "project/list",
        meta: {
          staff: false,
          director: true,
        },
      },
      {
        title: "Tasks",
        route: "project",
        meta: {
          staff: true,
          director: true,
        },
      },
      // {
      //   title: 'Milestones',
      //   route: 'project/milestone',
      //   meta: {
      //     staff: true,
      //   }
      // },
    ],
  },
  // {
  //   title: 'Recruitment',
  //   icon: 'UserPlusIcon',
  //   tag: 3,
  //   tagVariant: 'light-primary',
  //   meta: {
  //     staff: false,
  //     director: true
  //   },
  //   children: [
  //     {
  //       title: 'Dashboard',
  //       route: 'recruitment',
  //       meta: {
  //         staff: false,
  //         director: true
  //       },
  //     },
  //     {
  //       title: 'Recruitment Portal',
  //       route: 'recruitment/portal',
  //       meta: {
  //         staff: false,
  //         director: true
  //       },
  //     },
  //     {
  //       title: 'Job Request',
  //       route: 'recruitment/job-request',
  //       meta: {
  //         staff: false,
  //         director: true
  //       },
  //     }
  //   ]
  // },
  { header: "OTHERS", meta: { director: true } },
  {
    title: "Knowledge Base",
    route: "knowledge-base",
    icon: "BookOpenIcon",
    meta: {
      staff: true,
      director: true,
    },
  },
];
