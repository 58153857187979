<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <b-link class="nav-link" :to="{ name: 'calendar' }">
        <feather-icon icon="CalendarIcon" size="21" />
      </b-link>
      <b-link class="nav-link" :to="{ name: 'project' }">
        <feather-icon icon="CheckSquareIcon" size="21" />
      </b-link>
      <b-link class="nav-link" :to="{ name: 'apps-email' }">
        <feather-icon icon="MessageSquareIcon" size="21" />
      </b-link>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <b-link class="nav-link" :to="{ name: 'inbox' }">
        <feather-icon icon="BellIcon" size="21" />
      </b-link> -->

      <dark-Toggler class="d-none d-lg-block" />

      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user.name }}
            </p>
            <span class="user-status">{{ uppercase(getRole()) }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="user.profile_photo_path"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          :to="{ name: 'profile' }"
          link-class="d-flex align-items-center"
        >
          <b-link :to="{ name: 'profile' }" class="d-flex align-items-center">
            <feather-icon size="16" icon="UserIcon" class="mr-50" />
            <span>Profile</span>
          </b-link>
        </b-dropdown-item>

        <b-dropdown-item
          :to="{ name: 'inbox' }"
          link-class="d-flex align-items-center"
        >
          <b-link
            :to="{ name: 'apps-email' }"
            class="d-flex align-items-center"
          >
            <feather-icon size="16" icon="MailIcon" class="mr-50" />
            <span>Inbox</span>
          </b-link>
        </b-dropdown-item>

        <b-dropdown-item
          :to="{ name: 'todo' }"
          link-class="d-flex align-items-center"
        >
          <b-link :to="{ name: 'todo' }" class="d-flex align-items-center">
            <feather-icon size="16" icon="CheckSquareIcon" class="mr-50" />
            <span>Task</span>
          </b-link>
        </b-dropdown-item>

        <b-dropdown-item
          :to="{ name: 'calendar' }"
          link-class="d-flex align-items-center"
        >
          <b-link :to="{ name: 'calendar' }" class="d-flex align-items-center">
            <feather-icon size="16" icon="CalendarIcon" class="mr-50" />
            <span>Calendar</span>
          </b-link>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center text-danger"
          @click="logout"
        >
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  data() {
    return {
      user: {},
    };
  },

  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("sw_auth_data"));
  },
  methods: {
    uppercase(text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    },

    logout() {
      this.callApi({
        url: "/users/logout",
        method: "POST",
        success: (res) => {
          this.eraseCookie("sw_auth_token");
          localStorage.removeItem("sw_auth_data");
          this.$router.push({ name: "login" });
        },
      });
    },
  },
};
</script>
